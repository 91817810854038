import { lazy } from "react";

const AdsLayout = lazy(() => import("../layouts/AdsLayout"));

const TiltEquipement = lazy(() => import("../pages/Landing/TiltEquipement"));
const GondoleLanding = lazy(() => import("../pages/Landing/Gondole"));
const NoPage = lazy(() => import("../pages/NoPage"));

const MembersRoutes = {
  element: <AdsLayout />,
  children: [
    {
      path: "landing",
      element: <TiltEquipement />,
    },
    {
      path: "gondole-landing",
      element: <GondoleLanding />,
    },
    {
      path: "*",
      element: <NoPage />,
    },
  ],
};

export default MembersRoutes;
