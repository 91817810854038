import { ThemeProvider } from "@mui/material/styles";
import React, { Suspense, useContext, useEffect } from "react";
import CacheBuster from "react-cache-buster";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import packageInfo from "../package.json";
import MetaTagsMaster from "./components/utils/MetaMaster";
import { ConsentContext } from "./context/ConsentContext";
import Routes from "./routes";
import theme from "./theme";

function App() {
  const { consent } = useContext(ConsentContext);
  const location = useLocation();

  function gtag() {
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    try {
      if (consent && process.env.NODE_ENV === "production") {
        gtag("consent", "update", {
          analytics_storage: consent.analytics_storage ? "granted" : "denied",
          ad_storage: consent.ad_storage ? "granted" : "denied",
          ad_user_data: consent.ad_user_data ? "granted" : "denied",
          ad_personalization: consent.ad_personalization ? "granted" : "denied",
          personalization_storage: consent.personalization_storage
            ? "granted"
            : "denied",
          functionality_storage: "granted", // Supposant que cela reste toujours activé
          security_storage: "granted", // Supposant que cela reste toujours activé
        });
      }
    } catch (error) {
      console.error("Error reading or updating cookie consent:", error);
    }
  }, [consent]);

  useEffect(() => {
    // Suivi de la pageview si le consentement pour les analytics est accordé
    gtag("event", "page_view", {
      page_path: window.location.pathname + window.location.search,
    });
  }, [location]);

  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader"></div>
        </div>
      } //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "fixed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="loader"></div>
              </div>
            }
          >
            <MetaTagsMaster />
            <Routes />
          </Suspense>
        </ThemeProvider>
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;
