import React from "react";
import { Helmet } from "react-helmet-async";

export default function MetaTagsMaster({
  title,
  description,
  image,
  url,
  robots = true,
  jsonLd,
}) {
  const fullTitle = `${title} | Tilt' Équipement`;

  // Utilisation de `new URL` pour une gestion plus propre de l'URL
  const currentUrl = (() => {
    try {
      const baseUrl = new URL(url || window.location.href);
      baseUrl.search = ""; // Supprime les paramètres de requête
      return baseUrl.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url || window.location.href.split("?")[0];
    }
  })();

  const robotsTag = robots === true ? "index, follow" : "noindex, nofollow";

  return (
    <Helmet>
      {title && <title>{fullTitle}</title>}
      {title && <meta name="title" content={fullTitle} />}
      <meta name="robots" content={robotsTag} />
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={currentUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:url" content={currentUrl} />
      {title && <meta property="og:title" content={fullTitle} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta name="twitter:url" content={currentUrl} />
      {title && <meta name="twitter:title" content={fullTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* JSON-LD */}
      {jsonLd && (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      )}
    </Helmet>
  );
}
