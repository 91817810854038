import { lazy } from "react";
import { useRoutes } from "react-router-dom";

import MainRoutes from "./MainRoutes";
import AdsRoutes from "./AdsRoute";
const NoPage = lazy(() => import("../pages/NoPage"));

export default function Routes() {
  return useRoutes([MainRoutes, AdsRoutes, { path: "*", element: <NoPage /> }]);
}
