import "animate.css/animate.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
// import { applyMiddleware, createStore } from "redux";
// import thunk from "redux-thunk";
import "swiper/css";
import App from "./App";
// import rootReducer from "./reducers";
import "./styles/index.scss";

// devtools
import { BrowserRouter } from "react-router-dom";
// import { composeWithDevTools } from "redux-devtools-extension";
import { ConsentProvider } from "./context/ConsentContext";

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

createRoot(document.getElementById("root")).render(
  <BrowserRouter basename="/">
    <ConsentProvider>
      <App />
    </ConsentProvider>
  </BrowserRouter>
);
