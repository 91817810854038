import { lazy } from "react";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const Actualite = lazy(() => import("../pages/Actualite"));
const Actualites = lazy(() => import("../pages/Actualites"));
const Candidature = lazy(() => import("../pages/Candidature"));
const Catalogues = lazy(() => import("../pages/Catalogues"));
const Contact = lazy(() => import("../pages/Contact"));
const Devis = lazy(() => import("../pages/Devis"));
const Distributeur = lazy(() => import("../pages/Distributeur"));
const Emploie = lazy(() => import("../pages/Emploie"));
const Emploies = lazy(() => import("../pages/Emploies"));
const Entreprise = lazy(() => import("../pages/Entreprise"));
const Faq = lazy(() => import("../pages/Faq"));
const Favoris = lazy(() => import("../pages/Favoris"));
const Home = lazy(() => import("../pages/Home"));
const Inspiration = lazy(() => import("../pages/Inspiration"));
const Inspirations = lazy(() => import("../pages/Inspirations"));
const MentionsLegal = lazy(() => import("../pages/MentionsLegal"));
const NoPage = lazy(() => import("../pages/NoPage"));
const NoticesEtTutos = lazy(() => import("../pages/NoticesEtTutos"));
const PolitiqueConfidentialite = lazy(() =>
  import("../pages/PolitiqueConfidentialite")
);
const Product = lazy(() => import("../pages/Product"));
const Products = lazy(() => import("../pages/Products"));
const Realisation = lazy(() => import("../pages/Realisation"));
const Realisations = lazy(() => import("../pages/Realisations"));
const Recherches = lazy(() => import("../pages/Recherches"));
const Service = lazy(() => import("../pages/Service"));
const Showroom = lazy(() => import("../pages/Showroom"));
const SousUniver = lazy(() => import("../pages/SousUniver"));
const SREvolutif = lazy(() => import("../pages/SREvolutif"));
const Univer = lazy(() => import("../pages/Univer"));
const Univers = lazy(() => import("../pages/Univers"));

const MembersRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "inspirations",
      element: <Inspirations />,
    },
    {
      path: "inspirations/:slug",
      element: <Inspiration />,
    },
    {
      path: "realisations",
      element: <Realisations />,
    },
    {
      path: "realisations/:slug",
      element: <Realisation />,
    },
    {
      path: "univers",
      element: <Univers />,
    },
    {
      path: "univers/:slug",
      element: <Univer />,
    },
    {
      path: "sous-univers/:slug",
      element: <SousUniver />,
    },
    {
      path: "produits/:slug",
      element: <Product />,
    },
    {
      path: "produits",
      element: <Products />,
    },
    {
      path: "produits/categorie/:slug",
      element: <Products />,
    },
    {
      path: "sr-evolutif",
      element: <SREvolutif />,
    },
    {
      path: "entreprise",
      element: <Entreprise />,
    },
    {
      path: "espace-revendeur",
      element: <Distributeur />,
    },
    {
      path: "faq",
      element: <Faq />,
    },
    {
      path: "services",
      element: <Service />,
    },
    {
      path: "visite-virtuelle",
      element: <Showroom />,
    },
    {
      path: "actualites",
      element: <Actualites />,
    },
    {
      path: "actualites/:slug",
      element: <Actualite />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "emplois",
      element: <Emploies />,
    },
    {
      path: "emplois/:slug",
      element: <Emploie />,
    },
    {
      path: "candidature",
      element: <Candidature />,
    },
    {
      path: "candidature/:slug",
      element: <Candidature />,
    },
    {
      path: "devis",
      element: <Devis />,
    },
    {
      path: "recherches/:recherche",
      element: <Recherches />,
    },
    {
      path: "recherches/",
      element: <Recherches />,
    },
    {
      path: "catalogues",
      element: <Catalogues />,
    },
    {
      path: "notices-et-tutos",
      element: <NoticesEtTutos />,
    },
    {
      path: "favoris",
      element: <Favoris />,
    },
    {
      path: "politique-de-confidentialite",
      element: <PolitiqueConfidentialite />,
    },
    {
      path: "mentions-legales",
      element: <MentionsLegal />,
    },
    {
      path: "*",
      element: <NoPage />,
    },
  ],
};

export default MembersRoutes;
